import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import TextField from '../../FormElements/TextField';
import FormField from '../../FormElements/FormField';
import Checkbox from '../../FormElements/Checkbox';
import Button from '../../Button';
import { COLORS, SIZES } from '../../../../constants/theme';
import styles from './SocialForm.scss';
import SocialType from '../../../../types/SocialType';
import { LISTING_TYPE_ORGANIZATION, LISTING_TYPE_SPONSOR } from '../../../../constants/site';

const FORM_FIELD_NAMES = {
    websiteUrl: 'websiteUrl',
    youtubeUrl: 'youtubeUrl',
    facebookUrl: 'facebookUrl',
    linkedinUrl: 'linkedinUrl',
    instagramUrl: 'instagramUrl',
    donationUrl: 'donationUrl',
    twitterUsername: 'twitterUsername',
    liveStreaming: 'liveStreaming',
};

const urlRegex = /^(https?:\/\/)?(www\.)?[\w-]+\.[\w.-]+([/?#][^\s]*)?$/;

const socialSchema = Yup.object().shape({
    [FORM_FIELD_NAMES.websiteUrl]: Yup.string()
        .matches(urlRegex, 'Must be a valid URL'),
    [FORM_FIELD_NAMES.youtubeUrl]: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/?[\w\-./?&=%]*$/, 'Must be a valid YouTube URL'),
    [FORM_FIELD_NAMES.facebookUrl]: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?facebook\.com\/?[\w\-./?&=%]*$/, 'Must be a valid Facebook URL'),
    [FORM_FIELD_NAMES.linkedinUrl]: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/?[\w\-./?&=%]*$/, 'Must be a valid LinkedIn URL'),
    [FORM_FIELD_NAMES.instagramUrl]: Yup.string()
        .matches(/^(https?:\/\/)?(www\.)?instagram\.com\/?[\w\-./?&=%]*$/, 'Must be a valid Instagram URL'),
    [FORM_FIELD_NAMES.donationUrl]: Yup.string()
        .matches(urlRegex, 'Must be a valid URL'),
    [FORM_FIELD_NAMES.twitterUsername]: Yup.string()
        .matches(/^@?[a-zA-Z0-9_]{1,15}$/, 'Must be a valid X username'),
});

const SocialForm = ({
    uniqueId,
    socialData,
    updateSocials,
    listingType,
    onCancel,
    isHidden,
}) => {
    const initialValues = {
        [FORM_FIELD_NAMES.websiteUrl]: socialData.websiteUrl,
        [FORM_FIELD_NAMES.youtubeUrl]: socialData.youtubeUrl,
        [FORM_FIELD_NAMES.facebookUrl]: socialData.facebookUrl,
        [FORM_FIELD_NAMES.linkedinUrl]: socialData.linkedinUrl,
        [FORM_FIELD_NAMES.instagramUrl]: socialData.instagramUrl,
        [FORM_FIELD_NAMES.donationUrl]: socialData.donationUrl || '',
        [FORM_FIELD_NAMES.twitterUsername]: socialData.twitterUsername,
        [FORM_FIELD_NAMES.liveStreaming]: socialData.liveStreaming,
    };

    const onSubmit = (values) => {
        if (listingType === LISTING_TYPE_ORGANIZATION) {
            updateSocials({
                websiteUrl: values[FORM_FIELD_NAMES.websiteUrl] || '',
                youtubeUrl: values[FORM_FIELD_NAMES.youtubeUrl] || null,
                facebookUrl: values[FORM_FIELD_NAMES.facebookUrl] || null,
                linkedinUrl: values[FORM_FIELD_NAMES.linkedinUrl] || null,
                instagramUrl: values[FORM_FIELD_NAMES.instagramUrl] || null,
                donationUrl: values[FORM_FIELD_NAMES.donationUrl] || null,
                twitterUsername: values[FORM_FIELD_NAMES.twitterUsername] || null,
                liveStreaming: values[FORM_FIELD_NAMES.liveStreaming] || null,
            });
        } else if (listingType === LISTING_TYPE_SPONSOR) {
            updateSocials({
                websiteUrl: values[FORM_FIELD_NAMES.websiteUrl] || '',
                youtubeUrl: values[FORM_FIELD_NAMES.youtubeUrl] || null,
                facebookUrl: values[FORM_FIELD_NAMES.facebookUrl] || null,
                linkedinUrl: values[FORM_FIELD_NAMES.linkedinUrl] || null,
                instagramUrl: values[FORM_FIELD_NAMES.instagramUrl] || null,
                twitterUsername: values[FORM_FIELD_NAMES.twitterUsername] || null,
            });
        }
    };

    return (
        <div
            className={classNames(styles.container)}
        >
            <div
                className={classNames(styles.sheetForm)}
            >
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={socialSchema}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <FormField
                                label="Your Website URL"
                                component={TextField}
                                name={FORM_FIELD_NAMES.websiteUrl}
                                errors={errors[FORM_FIELD_NAMES.websiteUrl]}
                                touched={touched[FORM_FIELD_NAMES.websiteUrl]}
                                id={`${uniqueId}-website`}
                                required={false}
                                mediumWidth
                                disabled={isHidden}
                            />
                            <FormField
                                label="YouTube Page URL"
                                component={TextField}
                                name={FORM_FIELD_NAMES.youtubeUrl}
                                errors={errors[FORM_FIELD_NAMES.youtubeUrl]}
                                touched={touched[FORM_FIELD_NAMES.youtubeUrl]}
                                id={`${uniqueId}-youtube`}
                                required={false}
                                mediumWidth
                                disabled={isHidden}
                            />
                            <FormField
                                label="Facebook URL"
                                component={TextField}
                                name={FORM_FIELD_NAMES.facebookUrl}
                                errors={errors[FORM_FIELD_NAMES.facebookUrl]}
                                touched={touched[FORM_FIELD_NAMES.facebookUrl]}
                                id={`${uniqueId}-facebook`}
                                required={false}
                                mediumWidth
                                disabled={isHidden}
                            />
                            <FormField
                                label="LinkedIn URL"
                                component={TextField}
                                name={FORM_FIELD_NAMES.linkedinUrl}
                                errors={errors[FORM_FIELD_NAMES.linkedinUrl]}
                                touched={touched[FORM_FIELD_NAMES.linkedinUrl]}
                                id={`${uniqueId}-linkedIn`}
                                required={false}
                                mediumWidth
                                disabled={isHidden}
                            />
                            <FormField
                                label="Instagram Profile URL"
                                component={TextField}
                                name={FORM_FIELD_NAMES.instagramUrl}
                                errors={errors[FORM_FIELD_NAMES.instagramUrl]}
                                touched={touched[FORM_FIELD_NAMES.instagramUrl]}
                                id={`${uniqueId}-instagram`}
                                required={false}
                                mediumWidth
                                disabled={isHidden}
                            />
                            {(listingType === LISTING_TYPE_ORGANIZATION) && (
                                <FormField
                                    label="Donation URL"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.donationUrl}
                                    errors={errors[FORM_FIELD_NAMES.donationUrl]}
                                    touched={touched[FORM_FIELD_NAMES.donationUrl]}
                                    id={`${uniqueId}-donation`}
                                    required={false}
                                    mediumWidth
                                    disabled={isHidden}
                                />
                            )}
                            <FormField
                                label="X Username"
                                component={TextField}
                                name={FORM_FIELD_NAMES.twitterUsername}
                                errors={errors[FORM_FIELD_NAMES.twitterUsername]}
                                touched={touched[FORM_FIELD_NAMES.twitterUsername]}
                                id={`${uniqueId}-twitter`}
                                required={false}
                                shortWidth
                                linkText="@"
                                disabled={isHidden}
                            />
                            {(listingType === LISTING_TYPE_ORGANIZATION) && (
                                <>
                                    <span>
                                        Live Streaming:
                                    </span>
                                    <FormField
                                        label="Show that Live Streaming is Available"
                                        tooltip="Check the box to indicate that your
                                        church has live stream Mass available"
                                        component={Checkbox}
                                        name={FORM_FIELD_NAMES.liveStreaming}
                                        errors={errors[FORM_FIELD_NAMES.liveStreaming]}
                                        touched={touched[FORM_FIELD_NAMES.liveStreaming]}
                                        id={`${uniqueId}-livestreaming`}
                                        disabled={isHidden}
                                    />
                                </>
                            )}
                            <Button
                                size={SIZES.small}
                                type="submit"
                                disabled={isHidden}
                            >
                                Save
                            </Button>
                            <Button
                                color={COLORS.flat}
                                size={SIZES.small}
                                onClick={onCancel}
                                disabled={isHidden}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

SocialForm.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    socialData: SocialType.isRequired,
    updateSocials: PropTypes.func.isRequired,
    listingType: PropTypes.oneOf([LISTING_TYPE_ORGANIZATION, LISTING_TYPE_SPONSOR]).isRequired,
    onCancel: PropTypes.func.isRequired,
    isHidden: PropTypes.bool,
};

SocialForm.defaultProps = {
    isHidden: false,
};

export default SocialForm;
